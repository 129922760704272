<template>
  <div class="home">
    <LoaderComponent v-show="loading" :loaderText="loaderText" />
    <HeaderView />
    <section class="
          dashboard-page-main-sec
          order-page-main-sec
          track-order-main-sec
          agency-design-grid-right
          draft-order-page-sec
          domain-detail-page-sec
        ">

      <div class="row">
        <div class="col-md-12">
          <div class="order-input-field">
            <div class="reports-heading select">
              <b-form-select id="input-3" v-model="filter.order_id" :options="orderNumList" @change="searchOrder"></b-form-select>
            </div>
            <div class="date-picker-box">
              <div class="form-row">
                <div class="col-md-12">
                  <div class="download-icon-list">
                    <div @click="downloadFile($event)" class="top_icons">
                      Download Report 
                      <i class="fas fa-file-excel" v-b-tooltip.hover.top="'Export CSV'" data-type="csv"></i>
                      <i class="fas fa-file-pdf" v-b-tooltip.hover.top="'Export PDF'" data-type="pdf"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div class="order-count-list domain-box">
              <ul>
                  <li>
                    <div class="text-1">
                      <div class="icon-box" style="background: #F4F2FF">
                        <img src="@/assets/common/images/dashboard-icon-4.png" />
                      </div>
                    </div>
                    <div class="text-2" v-b-tooltip.hover.top="tooltipText">
                        <div class="count-text">
                          {{ stats.total_links }}
                        </div>
                        Total Links
                    </div>
                  </li>
                  <li>
                    <div class="text-1">
                      <div class="icon-box" style="background: #FF8E9E26">
                        <img src="@/assets/common/images/outreaching-icon.png" />
                      </div>
                
                    </div>

                    <div class="text-2">
                      <div
                        class="count-text"
                        v-text="stats.total_in_outreaching"
                      >
                      </div>
                      In Outreaching
                    </div>
                  </li>
                  <li>
                    <div class="text-1">
                      <div class="icon-box" style="background: #FEB01926">
                        <img src="@/assets/common/images/writing-icon.png" />
                      </div>
                
                    </div>

                    <div class="text-2">
                      <div
                        class="count-text"
                        v-text="stats.total_in_writing"
                      >
                      </div>
                      In Writing
                    </div>
                  </li>
                  <li>
                    <div class="text-1">
                      <div class="icon-box" style="background: #8F7AD826">
                        <img src="@/assets/common/images/submission-icon.png" />
                      </div>
                
                    </div>

                    <div class="text-2">
                      <div
                        class="count-text"
                        v-text="stats.total_in_submission"
                      >
                      </div>
                      In Submission
                    </div>
                  </li>
                  <li>
                    <div class="text-1">
                      <div class="icon-box" style="background: #00E39626">
                        <img src="@/assets/common/images/live-links-icon.png" />
                      </div>
                
                    </div>

                    <div class="text-2">
                      <div
                        class="count-text"
                        v-text="stats.total_delivered_links"
                      >
                      </div>
                      Live Links
                    </div>
                  </li>
              </ul>
            </div>
            <div class="text-heading-2">
              Links
          </div>
          <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
            <div class="table-responsive">
              <table class="addon-table">
                <tr>
                  <th>Link ID </th>
                  <th>DA/DR</th>
                  <!-- <th v-if="orderType == 'MO'">DR</th> -->
                  <th>Destination Url</th>
                  <th>Live Url</th>
                  <th>Anchor Text</th>
                  <th>Published DA</th>
                  <th>Published Date</th>
                  <th>Status</th>
                </tr>
                <tr v-for="orderDetail in order_detail_data" :key="orderDetail.order_id">
                  <td>{{ orderDetail.link_id }}</td>
                  <!-- <td class="da-text" v-if="orderDetail.orderType == 'MO'">{{ orderDetail.exact_da }}</td>
                  <td class="da-text" v-else>{{ orderDetail.ordered_da }}</td> -->
                  <td class="da-text">{{ orderDetail.ordered_da }}</td>
                  <!-- <td class="dr-text" v-if="orderDetail.orderType == 'MO'">{{ orderDetail.exact_dr }}</td> -->
                  <td class="destination-text" v-b-tooltip.hover.top="orderDetail.destination_link">
                    <a @click="redirectURL(orderDetail.destination_link)"
                      ><span>{{ orderDetail.destination_link }}</span>
                      <i
                        v-if="orderDetail.destination_link !== true
                            ? orderDetail.destination_link
                            : orderDetail.destination_link
                        "
                        class="fas fa-external-link-alt"
                      ></i
                    ></a>
                  </td>
                  <td class="destination-text" v-b-tooltip.hover.top="orderDetail.live_link"><template v-if="orderDetail.live_link !== true
                        ? orderDetail.live_link
                        : orderDetail.live_link
                    "><a @click="redirectURL(orderDetail.live_link)"><span>{{ orderDetail.live_link }} </span> <i v-if="orderDetail.live_link !== true
                      ? orderDetail.live_link
                      : orderDetail.live_link
                  " class="fas fa-external-link-alt"></i></a></template></td>
                  <td><div v-b-tooltip.hover.top="orderDetail.anchor_text">{{ orderDetail.anchor_text }}</div></td>
                  <td>{{ orderDetail.da }}</td>    
                  <td>{{ orderDetail.published_at }}</td>
                  <td class="status-btns">
                      <a
                        class="btn-success" style="backgroundColor: #41b883; color: #fff"
                        v-if="orderDetail.status == 'Delivered'"
                        >{{ orderDetail.status }}</a
                      >
                      <a
                        class="btn-secondary" style="backgroundColor: #f4516c; color: #fff"
                        v-if="orderDetail.status == 'Not Delivered'"
                        >{{ orderDetail.status }}</a
                      >
                      <a
                        class="btn-secondary" style="backgroundColor: #ffb822; color: #fff"
                        v-if="orderDetail.status == 'Outreaching'"
                        >{{ orderDetail.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #00b6cb; color: #fff"
                        v-if="orderDetail.status == 'In Writing'"
                        >{{ orderDetail.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #36a3f7; color: #fff"
                        v-if="orderDetail.status == 'Pending Review'"
                        >{{ orderDetail.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #9816f4; color: #fff"
                        v-if="orderDetail.status == 'Refund'"
                        >{{ orderDetail.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #36a3f7; color: #fff"
                        v-if="orderDetail.status == 'Link Submission'"
                        >{{ orderDetail.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: red; color: #fff"
                        v-if="orderDetail.status == 'Cancel'"
                        >{{ orderDetail.status }}</a
                      >
                    </td>
                </tr>
              </table>
              <div class="total-records-list">
                <p>Showing {{ rows.length }} of {{ totalRecords }} records</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-list">
        <paginate
          :click-handler="selectedOrderID"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="2"
          :container-class="'className'"
          :records="length"
          v-model="page"
          :per-page="per_page"
        >
        </paginate>
      </div>
    </section>
  </div>
</template>

<style>
   .tooltip-inner {
    white-space: pre-wrap;
    text-align: left !important;
   }

</style>

<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import Paginate from "vuejs-paginate-next";
export default {
  components: {
    HeaderView,
    LoaderComponent,
    Paginate
  },
  mounted() {
    this.orderList();
    this.selectedOrderID();
  },
  data() {
    return {
      loading: false,
      date: new Date(),
      dateFormat: 'dd-MM-yyyy',
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
      },
      rows: [],
      totalRecords: 0,
      per_page: 50,
      page: 1,
      last_page: 1,
      length: "",
      filter: {
        columnTo: "destination_link",
        orderBy: "desc",
        order_id: "",
        download: 'no',
        fileType: ''
      },
      order_detail_data: [],
      stats: {
        total_links: 0,
        total_in_outreaching: 0,
        total_in_writing: 0,
        total_in_submission: 0,
        total_delivered_links: 0,
        total_pending_links: 0,
      },
      orderNumList: [],
      ordered_da: [],
      loaderText: '',
      orderType: 'Normal',
    };
  },
  methods: {
    async orderList() {
      try {
        let res = await this.$store.dispatch("AgencyReport/getOrderIdDataAction");
        res.orders.forEach((item) => {
          this.orderNumList.push({ text: 'Order # ' + item.id, value: item.id });
        });
      } catch (e) {
        this.loading = false;
      }
    },
    selectedOrderID(){
      var url = window.location.pathname;
      var order_id = url.substring(url.lastIndexOf('/') + 1);
      if (order_id) {
        this.getOrderReportDetail(order_id);
      }
    },
    searchOrder(order_id){
      this.page = 1;
      this.filter.fileType = '';
      this.getOrderReportDetail(order_id);
    },
    async getOrderReportDetail(order_id) {
      this.loading = true;
      this.filter.order_id = order_id;
      this.filter.download = 'no';
      try {
        let res = await this.$store.dispatch(
          "AgencyReport/OrderReportDetailDataAction",
          { 
            filters: this.filter,
            per_page: this.per_page, 
            page: this.page 
          }
        );
        if(res.data.length > 0){
          this.orderType = res.data[0].orderType;
        }
        this.order_detail_data = res.data;
        this.totalRecords = res.meta.total;
        this.rows = res.data;
        this.page = res.meta.current_page;
        this.per_page = res.meta.per_page;
        this.last_page = res.meta.last_page;
        this.length = res.meta.total;
        this.ordered_da = res.ordered_da;
        this.stats.total_links = res.total_links;
        this.stats.total_in_outreaching = res.total_in_outreaching;
        this.stats.total_in_writing = res.total_in_writing;
        this.stats.total_in_submission = res.total_in_submission;
        this.stats.total_delivered_links = res.total_delivered_links;
        this.stats.total_pending_links = res.total_pending_links;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$router.push("/dashboard");
      }
    },

    async downloadFile(event) {
      this.filter.fileType = event.target.getAttribute("data-type");
      this.loaderText = "Please wait while we are processing your request";
      this.loading = true;
      this.filter.download = "yes";

      try {
        let response = await this.$store.dispatch(
          "AgencyReport/OrderReportDetailDataAction",
          { 
            order_id: this.filter.order_id,
            filters: this.filter,
            per_page: this.per_page, 
            page: this.page,
          }
        );

        let blobType = this.filter.fileType === "pdf" ? "application/pdf" : "text/csv";
        let fileName = this.filter.fileType === "pdf" ? "Order-Detail.pdf" : "Order-Detail.csv";

        let blob = new Blob([response], { type: blobType });

        // PDF/CSV download process
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } catch (e) {
        console.error("Download Error: ", e);
      } finally {
        this.loaderText = "";
        this.loading = false;
      }
    },


    redirectURL(url) {
            const pattern = /^(http:\/\/|https:\/\/)/i;
            const hasHttpOrHttps = pattern.test(url);

            let finalUrl = url;
            if (!hasHttpOrHttps) {
                finalUrl = 'https://' + url;
            }

            const newTab = window.open(finalUrl, '_blank');
            if (newTab) {
                newTab.focus();
            }
        },
  },
  computed: {
    tooltipText() {
      const tooltipLabel = this.ordered_da
        .filter(item => item.da_count > 0)
        .map(item => `${item.da_label} : ${item.da_count}\n`)
        .join('');

      return tooltipLabel;
    },
  },
};
</script>
